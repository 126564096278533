import React, { FC } from 'react';

interface IChartPlaceholderProps {
  text: string;
}

export const ChartPlaceholder: FC<IChartPlaceholderProps> = ({ text }) => (
  <div className="relative p-[30px] h-[350px] w-full bg-error flex items-center justify-center rounded-md">
    <div className="text-center font-semibold text-error-intense">{text}</div>
  </div>
);
