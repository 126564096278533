import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from '../../components/Header/Header';
import { Button } from '../../components/Button/Button';
import { Wizard } from '../../components/Wizard/Wizard';
import { TablePage } from '../../components/studentPages/TablePage';
import { SelectGraphPage } from '../../components/studentPages/SelectGraphPage';
import { useStores } from '../../providers/store/use-stores';
import { DescriptionPage } from '../../components/studentPages/DescriptionPage';
import { SolutionView } from '../SolutionView/SolutionView';
import { isSafari } from 'asu-sim-toolkit';
import { IconId } from '../../icon-definitions';
import { View } from '../../stores/domain';

export const StudentView = observer(() => {
  const pages = [TablePage, SelectGraphPage, DescriptionPage];
  const [stepIndex, setStepIndex] = useState(0);
  const {
    assignmentConfigStore: { savedConfig },
    solutionStore: { save, isSaved, submit, canSubmit, view, isSubmitted },
    fullscreenStore: { toggleFullscreen, fullscreenControlState, isFullscreen },
  } = useStores();

  if (view === View.Overview) {
    return <SolutionView />;
  }

  const headerAction =
    fullscreenControlState.isVisible && !isSafari
      ? {
          iconId: (isFullscreen ? 'fullscreen_exit' : 'fullscreen') as IconId,
          disabled: !fullscreenControlState.isEnabled,
          onClick: toggleFullscreen,
        }
      : undefined;

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={savedConfig.title} action={headerAction}>
        {isSubmitted ? (
          <Button onClick={submit} disabled={!canSubmit}>
            Resubmit
          </Button>
        ) : (
          <div className="flex gap-3">
            <Button onClick={save} disabled={isSaved}>
              Save
            </Button>
            <Button onClick={submit} disabled={!canSubmit}>
              Submit
            </Button>
          </div>
        )}
      </Header>
      <Wizard
        stepIndex={stepIndex}
        stepComponents={pages}
        onStepChange={setStepIndex}
      />
    </div>
  );
});
