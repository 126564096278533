import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStores } from '../../providers/store/use-stores';
import { ChartData } from '../../stores/domain';
import { IRemoteDataStore, RemoteDataStoreStatus } from '../../stores/types';
import { DataTableModal } from '../modals/DataTableModal';
import { DataSourcesInputItem } from './DataSourcesInputItem';

export interface IDataSourcesInputProps {
  label: string;
  inputDescription?: string;
  value: string[];
  dataSources: IRemoteDataStore<
    ChartData,
    {
      sheetUrl: string;
    }
  >[];

  updateDataSource: (url: string | null, index: number) => Promise<void>;
  onChange(newValue: string[]): void;
}

export const DataSourcesInput: FC<IDataSourcesInputProps> = observer(
  ({
    label,
    inputDescription,
    value,
    dataSources,
    onChange,
    updateDataSource,
  }) => {
    const { modalStore } = useStores();
    const handleItemChange = (index: number, newItemValue: string) => {
      const newValue = value.slice();

      newValue[index] = newItemValue;
      onChange(newValue);
    };

    const handleItemBlur = (index: number, itemValue: string) => {
      updateDataSource(itemValue, index);
    };

    const handleItemDelete = (index: number) => {
      const newValue = value.slice();

      newValue.splice(index, 1);
      onChange(newValue);
      updateDataSource(null, index);
    };

    const viewDataTable = (data: any) =>
      modalStore.modal(DataTableModal, {
        title: 'Data table',
        confirmLabel: 'Ok',
        data,
      });

    return (
      <div>
        <div className="font-semibold">{label}</div>
        <div className="flex flex-col gap-sm">
          {[...value, ''].map((v, i) => (
            <div key={`array-input-item-${i}`}>
              <DataSourcesInputItem
                value={v}
                index={i}
                onChange={handleItemChange}
                onDelete={i < value.length ? handleItemDelete : undefined}
                description={inputDescription}
                onBlur={handleItemBlur}
                status={dataSources[i]?.status || RemoteDataStoreStatus.IDLE}
              />
              {dataSources[i] &&
                dataSources[i].status === RemoteDataStoreStatus.READY && (
                  <div className="text-success text-center mt-1">
                    <span>Data fetched successfully. </span>
                    <button
                      onClick={() => viewDataTable(dataSources[i].data)}
                      className="font-semibold underline"
                    >
                      Click here to see the data table
                    </button>
                  </div>
                )}
              {dataSources[i] &&
                dataSources[i].status === RemoteDataStoreStatus.ERROR && (
                  <div className="text-error-intense text-center mt-1">
                    <span>
                      The URL or downloaded data is in an invalid format.
                    </span>
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
