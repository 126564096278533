import React, { FC, PropsWithChildren, ReactNode } from 'react';

export interface IInstructorViewSectionProps extends PropsWithChildren {
  title: ReactNode;
}

export const InstructorViewSection: FC<IInstructorViewSectionProps> = ({
  title,
  children,
}) => (
  <div className="p-6">
    <h3 className="font-semibold text-lg">{title}</h3>
    <div className="p-3 flex flex-col gap-3">{children}</div>
  </div>
);
