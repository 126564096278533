import { CapiBoundStore, ICAPI, Mapper } from 'asu-sim-toolkit';
import { ICapiModel } from '../capi';
import {
  IAssignmentConfigStore,
  IRemoteDataStore,
  IRootStore,
  RemoteDataStoreStatus,
} from './types';
import {
  ChartData,
  FlatAssignmentConfig,
  IAssignmentConfig,
  IToolSpecificAssignmentConfig,
} from './domain';
import { action, computed, makeObservable, observable } from 'mobx';
import { isDebugMode } from '../config';
import { serialize, unserialize } from './helpers';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import { RemoteDataStore } from './remote-data-store';
import { chartDataSource } from '../sources/chart-data-source-google-sheets';

// NOTE: Simulates initial CAPI payload. I'm not sure this is how it works
//       it's possible the app does not load until the initial data makes
//       its way into simcapi and then it all happens synchronously.
//       We'll have to find out.
const SIMULATE_INIT = false;

const EMPTY_CONFIG: IAssignmentConfig = {
  title: '',
  summary: '',
  allowResubmit: false,
  data: {
    dataSourceUrls: [],
    minWords: 50,
    maxScore: 100,
    objective: '',
  },
};

const mapAssignmentConfigToCapi: Mapper<
  IToolSpecificAssignmentConfig,
  string
> = (input) => serialize(input);

const mapAssignmentConfigFromCapi: Mapper<
  string,
  IToolSpecificAssignmentConfig
> = (input: string) => {
  if (!input) return EMPTY_CONFIG.data;

  try {
    return unserialize(input);
  } catch (e) {
    console.warn('Could not parse assignment config');
    return EMPTY_CONFIG.data;
  }
};

class AssignmentConfig extends CapiBoundStore<ICapiModel> {
  title: string;
  summary?: string;
  allowResubmit: boolean;
  data: IToolSpecificAssignmentConfig;

  constructor(
    capi: ICAPI<ICapiModel>,
    initialConfig: IAssignmentConfig,
    synchronize: 'twoWay' | 'fromCAPI'
  ) {
    super(capi);

    this.title = initialConfig.title;
    this.summary = initialConfig.summary;
    this.allowResubmit = initialConfig.allowResubmit;
    this.data = initialConfig.data;

    makeObservable(this, {
      title: observable,
      summary: observable,
      allowResubmit: observable,
      data: observable,
    });

    if (synchronize === 'twoWay') {
      this.bindToCapi('title', 'Sim.Configuration.Assignment.Title');
      this.bindToCapi('summary', 'Sim.Configuration.Assignment.Summary');
      this.bindToCapi('allowResubmit', 'Sim.Configuration.Assignment.Resubmit');
      this.bindToCapi(
        'data',
        'Sim.Configuration.Assignment.Data',
        mapAssignmentConfigFromCapi,
        mapAssignmentConfigToCapi
      );
    }

    if (synchronize === 'fromCAPI') {
      this.synchronizeFromCapi('title', 'Sim.Configuration.Assignment.Title');
      this.synchronizeFromCapi(
        'summary',
        'Sim.Configuration.Assignment.Summary'
      );
      this.synchronizeFromCapi(
        'allowResubmit',
        'Sim.Configuration.Assignment.Resubmit'
      );
      this.synchronizeFromCapi(
        'data',
        'Sim.Configuration.Assignment.Data',
        mapAssignmentConfigFromCapi
      );
    }

    if (isDebugMode && SIMULATE_INIT) {
      setTimeout(() => {
        this.capi.set('Sim.Configuration.Assignment.Title', 'Assignment 001');
        this.capi.set(
          'Sim.Configuration.Assignment.Data',
          'eyJkYXRhU291cmNlVXJscyI6WyIxTVlkRU12VTJNc1NPa1NRclc2dXpzNlcwdWVIS0FEYlI2VjBOR2hCb2xOayIsIjFGOEpydXlBZ1Zicy0wWHAtNVZwR1Z6OF9GWWJkQXlCRTJ6SDhkSFNXT1dFIl0sIm9iamVjdGl2ZSI6Ikp1c3QgZG8geW91ciB0aGluZy4iLCJtaW5Xb3JkcyI6MTAsIm1heFNjb3JlIjoxMDB9'
        );
      }, 100);
    }
  }
}

export interface IDataSource {
  url: string;
  status: RemoteDataStoreStatus;
  data: any;
}

export class AssignmentConfigStore
  extends CapiBoundStore<ICapiModel>
  implements IAssignmentConfigStore
{
  private readonly rootStore: IRootStore;
  currentConfig: AssignmentConfig;
  savedConfig: AssignmentConfig;
  dataSources: IRemoteDataStore<ChartData, { sheetUrl: string }>[];

  isChanged = false;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.currentConfig = new AssignmentConfig(capi, EMPTY_CONFIG, 'fromCAPI');
    this.savedConfig = new AssignmentConfig(capi, EMPTY_CONFIG, 'twoWay');
    this.rootStore = rootStore;
    this.dataSources = [];

    makeObservable(this, {
      isChanged: observable,
      canSave: computed,
      dataSources: observable,

      updateData: action.bound,
      save: action.bound,
      discard: action.bound,
      updateDataSource: action.bound,
    });
  }

  updateData(changedFields: Partial<FlatAssignmentConfig>) {
    this.isChanged = true;

    if (changedFields.title !== undefined) {
      this.currentConfig.title = changedFields.title;
    }

    if (changedFields.summary !== undefined) {
      this.currentConfig.summary = changedFields.summary;
    }

    if (changedFields.allowResubmit !== undefined) {
      this.currentConfig.allowResubmit = changedFields.allowResubmit;
    }

    this.currentConfig.data = { ...this.currentConfig.data, ...changedFields };
  }

  async updateDataSource(url: string | null, index: number) {
    if (url === '') return;

    if (url === null) {
      const newDataSources = [...this.dataSources];
      newDataSources.splice(index, 1);
      this.dataSources = newDataSources;
      return;
    }

    this.dataSources[index] = new RemoteDataStore<
      ChartData,
      { sheetUrl: string }
    >(chartDataSource.fetchChartData);

    if (!new RegExp('http(s|)://docs.google.com/spreadsheets/d/.+').test(url)) {
      this.dataSources[index].status = RemoteDataStoreStatus.ERROR;
      return;
    }

    await this.dataSources[index].fetch({ sheetUrl: url });
  }

  async save() {
    if (this.savedConfig.title) {
      const isConfirmed = await this.rootStore.modalStore.modal(
        ConfirmationModal,
        {
          title: 'Be careful!',
          message:
            'The students could already start working on this assignment. Are you sure you want to edit the details of this task?',
          confirmLabel: 'Yes',
          denyLabel: 'Cancel',
        }
      );

      if (!isConfirmed) return;
    }

    this.savedConfig.title = this.currentConfig.title;
    this.savedConfig.summary = this.currentConfig.summary;
    this.savedConfig.allowResubmit = this.currentConfig.allowResubmit;
    this.savedConfig.data = this.currentConfig.data;
    this.isChanged = false;
    this.rootStore.notificationStore.addNotification(
      'Assignment configuration has been saved.'
    );
  }

  async discard() {
    const isConfirmed = await this.rootStore.modalStore.modal(
      ConfirmationModal,
      {
        title: 'Are you sure?',
        message: 'Are you sure you want to discard the changes?',
        confirmLabel: 'Discard',
        denyLabel: 'Cancel',
      }
    );

    if (!isConfirmed) return;

    this.currentConfig.title = this.savedConfig.title;
    this.currentConfig.summary = this.savedConfig.summary;
    this.currentConfig.data = this.savedConfig.data;
    this.isChanged = false;
    this.dataSources = this.savedConfig.data.dataSourceUrls.map((url) => {
      const remoteDataStore = new RemoteDataStore<
        ChartData,
        { sheetUrl: string }
      >(chartDataSource.fetchChartData);

      if (
        !new RegExp('http(s|)://docs.google.com/spreadsheets/d/.+').test(url)
      ) {
        remoteDataStore.status = RemoteDataStoreStatus.ERROR;
        return remoteDataStore;
      }

      remoteDataStore.fetch({ sheetUrl: url });

      return remoteDataStore;
    });
  }

  get canSave() {
    return (
      this.isChanged &&
      !this.dataSources.some((ds) => ds.status === RemoteDataStoreStatus.ERROR)
    );
  }
}
