import classNames from 'classnames';
import React, { ChangeEvent, KeyboardEventHandler } from 'react';
import { RemoteDataStoreStatus } from '../../stores/types';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner/Spinner';

export interface IDataSourcesInputItemProps {
  index: number;
  value: string;
  description?: string;
  status?: RemoteDataStoreStatus;

  onChange(index: number, newValue: string): void;
  onBlur(index: number, value: string): void;
  onDelete?(index: number): void;
}

export const DataSourcesInputItem = ({
  index,
  value,
  description,
  status,
  onDelete,
  onChange,
  onBlur,
}: IDataSourcesInputItemProps) => {
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const v = ev.currentTarget.value;
    if (v === '' && onDelete) {
      onDelete(index);
      return;
    }
    onChange(index, v);
  };

  const handleBlur = (ev: ChangeEvent<HTMLInputElement>) => {
    const v = ev.currentTarget.value;
    onBlur(index, v);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({
    currentTarget,
    key,
  }) => {
    if (key === 'Enter') {
      const nextInput =
        currentTarget.parentElement?.parentElement?.querySelector(
          `input[data-item-id="${index + 1}"]`
        );
      if (!nextInput) {
        return;
      }
      (nextInput as HTMLInputElement).focus();
    }
  };

  const statusClasses =
    status === RemoteDataStoreStatus.IDLE
      ? 'border-gray-700'
      : status === RemoteDataStoreStatus.READY
      ? 'border-success'
      : 'border-error-intense';

  return (
    <div>
      <span className="text-xs text-gray-700 font-normal">{description}</span>
      <div className="flex gap-1 items-center relative">
        <input
          className={classNames(
            'flex-1 rounded-md border px-3 py-1 text-md font-normal pr-[42px] truncate',
            statusClasses
          )}
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          data-item-id={index}
          disabled={status === RemoteDataStoreStatus.FETCHING}
        />
        {onDelete && (
          <div
            className={classNames(
              'w-[36px] h-[34px] absolute right-0 border-l',
              statusClasses
            )}
          >
            {status === RemoteDataStoreStatus.FETCHING ? (
              <Spinner scale={0.7} />
            ) : (
              <button
                className="w-full h-full"
                type="button"
                onClick={() => onDelete(index)}
              >
                <Icon className="text-lg" id="close" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
