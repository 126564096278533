import classNames from 'classnames';
import React, { FC } from 'react';

interface ICheckbox {
  label?: string;
  disabled?: boolean;
  value: boolean;

  onChange(value: boolean): void;
}

export const Checkbox: FC<ICheckbox> = ({
  label,
  disabled,
  value,
  onChange,
}) => (
  <label className="w-full flex items-center">
    <input
      className={classNames('mr-2', disabled && 'cursor-not-allowed')}
      type="checkbox"
      checked={value}
      onChange={(event) => onChange(event.target.checked)}
      disabled={disabled}
    />
    <span className="text-sm text-black font-normal">{label}</span>
  </label>
);
