import React, { FC } from 'react';
import { Button, buttonBlueClasses } from '../Button/Button';
import { Icon } from '../Icon';

interface IWizardProps {
  stepIndex: number;
  stepComponents: FC[];

  onStepChange(step: number): void;
}

export const Wizard = ({
  stepIndex,
  stepComponents,
  onStepChange,
}: IWizardProps) => {
  const numberOfSteps = stepComponents.length;
  const hasPreviousPage = stepIndex > 0;
  const hasNextPage = stepIndex < numberOfSteps - 1;

  return (
    <div className="h-full flex flex-col p-6 overflow-y-auto">
      <div className="flex-1">
        {React.createElement(stepComponents[stepIndex])}
      </div>
      <div className="mt-6 flex justify-between">
        {hasPreviousPage && (
          <Button
            onClick={() => onStepChange(stepIndex - 1)}
            className={buttonBlueClasses}
          >
            <Icon id="previous" className="mr-2" /> Previous page
          </Button>
        )}
        <div className="flex-1" />
        {hasNextPage && (
          <Button
            onClick={() => onStepChange(stepIndex + 1)}
            className={buttonBlueClasses}
          >
            Next <Icon id="next" className="ml-2" />
          </Button>
        )}
      </div>
    </div>
  );
};
