import { IChartDataSource } from './types';

async function fetchRawSheetData(url: string) {
  const jsonUrl = url.split('/').slice(0, -1).join('/') + '/gviz/tq';
  const r = await fetch(jsonUrl);

  if (r.status !== 200) {
    throw new Error('could not fetch data');
  }

  return r.text();
}

interface IGoogleSheetCell {
  v: string | number;
}

interface IGoogleSheetRow {
  c: IGoogleSheetCell[];
}

interface IGoogleSheetColumn {
  label: string;
}

async function fetchChartData({ sheetUrl }: { sheetUrl: string }) {
  const data = await fetchRawSheetData(sheetUrl);
  const parsed = JSON.parse(
    data
      .split('\n')[1]
      .replace(/google.visualization.Query.setResponse\(|\);/g, '')
  );

  const result: (string | number)[][] = [];

  if (parsed?.table?.cols) {
    result.push(parsed.table.cols.map((c: IGoogleSheetColumn) => c.label));
  }

  if (parsed?.table?.rows) {
    parsed.table.rows.forEach((r: IGoogleSheetRow) =>
      result.push(r.c.map((c) => c.v))
    );
  }

  return result;
}

export const chartDataSource: IChartDataSource = {
  fetchChartData,
};
