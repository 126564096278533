import {
  IFullscreenStore,
  IModalStore,
  INotificationStore,
  IRecapStore,
  ISimContextStore,
} from 'asu-sim-toolkit';

import {
  ChartData,
  FlatAssignmentConfig,
  IAssignmentConfig,
  ISolution,
  View,
} from './domain';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppStore {}

export interface IAssignmentConfigStore {
  currentConfig: IAssignmentConfig;
  isChanged: boolean;
  canSave: boolean;
  savedConfig: IAssignmentConfig;
  dataSources: IRemoteDataStore<ChartData, { sheetUrl: string }>[];

  discard(): void;
  save(): Promise<void>;
  updateData(changedFields: Partial<FlatAssignmentConfig>): void;
  updateDataSource(url: string | null, index: number): Promise<void>;
}

export interface ISolutionStore {
  isSaved: boolean;
  isSubmitted: boolean;
  canSubmit: boolean;
  solution: ISolution;
  wordCount: number;
  dataSetIndex: number;
  view: View;
  autograderDescription: string;

  updateSolution(newData: Partial<ISolution>): void;
  save(): void;
  submit(): void;
  changeView(view: View): void;
}

export enum RemoteDataStoreStatus {
  IDLE,
  FETCHING,
  READY,
  ERROR,
}

export interface IRemoteDataStore<T, O> {
  data: T | null;
  status: RemoteDataStoreStatus;

  fetch(options: O): Promise<void>;
}

export interface IRootStore {
  appStore: IAppStore;
  assignmentConfigStore: IAssignmentConfigStore;
  solutionStore: ISolutionStore;
  chartDataStore: IRemoteDataStore<ChartData, { sheetUrl: string }>;
  fullscreenStore: IFullscreenStore;
  simContextStore: ISimContextStore;
  modalStore: IModalStore;
  notificationStore: INotificationStore;
  recapStore: IRecapStore;
}
