import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Chart,
  GoogleChartEditor,
  GoogleChartWrapper,
  GoogleViz,
} from 'react-google-charts';

import { useResize } from '../../hooks/use-resize';
import { useStores } from '../../providers/store/use-stores';
import { Button, buttonSecondaryClasses } from '../Button/Button';
import { DataTableModal } from '../modals/DataTableModal';
import { ChartPlaceholder } from '../Chart/ChartPlaceholder';
import { RemoteDataRenderer } from '../RemoteDataRenderer/RemoteDataRenderer';
import { ChartData } from '../../stores/domain';

export const SelectGraphPage: FC = observer(() => {
  const {
    modalStore,
    solutionStore: { solution, updateSolution },
    chartDataStore: { status, data },
  } = useStores();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartEditor, setChartEditor] = useState<GoogleChartEditor>();
  const [chartWrapper, setChartWrapper] = useState<GoogleChartWrapper>();
  const [google, setGoogle] = useState<GoogleViz>();

  const onEditClick = () => {
    if (!chartWrapper || !google || !chartEditor) {
      return;
    }

    chartEditor.openDialog(chartWrapper);

    google.visualization.events.addListener(chartEditor, 'ok', () => {
      const newChartWrapper = chartEditor.getChartWrapper();

      newChartWrapper.draw();

      const newChartType = newChartWrapper.getChartType();
      const newChartOptions = newChartWrapper.getOptions();

      updateSolution({
        chartSettings: { type: newChartType, options: newChartOptions },
      });
    });
  };

  useEffect(() => {
    if (google && chartEditor) {
      onEditClick();
    }
    return () => {
      chartEditor?.closeDialog();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  useResize(chartContainerRef, (width) => {
    setChartWidth(width);
  });

  const viewDataTable = () =>
    modalStore.modal(DataTableModal, {
      title: 'Data table',
      confirmLabel: 'Ok',
      data,
    });

  return (
    <div>
      <p>
        Use the controls below to select the graph and manipulate it in order to
        accurately represent the task. You may click on the table button at any
        point to view the data.
      </p>
      <p className="mt-6 mb-2">
        <span className="font-bold">Note:</span> Your chart may look different
        depending on the size of your screen.
      </p>
      <div className="flex gap-4">
        <Button className={buttonSecondaryClasses} onClick={viewDataTable}>
          View data table
        </Button>
        <Button className={buttonSecondaryClasses} onClick={onEditClick}>
          Edit Chart
        </Button>
      </div>
      <div className="mt-4 flex gap-3">
        <div
          className="flex-1 my-3 overflow-hidden w-full flex justify-center"
          ref={chartContainerRef}
        >
          <RemoteDataRenderer status={status} data={data}>
            <Chart
              key={solution.chartSettings.type}
              style={{
                display: !solution.chartSettings.type ? 'none' : 'block',
              }}
              chartType={solution.chartSettings.type}
              data={data as ChartData}
              getChartEditor={({ chartEditor, chartWrapper, google }) => {
                setGoogle(google);
                setChartEditor(chartEditor);
                setChartWrapper(chartWrapper);
              }}
              options={{
                ...solution.chartSettings.options,
                width: chartWidth,
              }}
              chartPackages={['corechart', 'controls', 'charteditor']}
              height="450px"
              width="100%"
            />
            {!solution.chartSettings.type && (
              <ChartPlaceholder text="Please select a graph to see a preview." />
            )}
          </RemoteDataRenderer>
        </div>
      </div>
    </div>
  );
});
