import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.Saved': boolean;
  'Sim.Submitted': boolean;

  'Sim.Configuration.Assignment.Title': string;
  'Sim.Configuration.Assignment.Summary': string;
  'Sim.Configuration.Assignment.Resubmit': boolean;

  // Base64 data
  'Sim.Configuration.Assignment.Data': string;

  'Sim.Solution.Description': string;
  // Base64 settings
  'Sim.Solution.ChartSettings': string;
  'Sim.Solution.DataSetIndex': number;

  // AI-friendly solution data
  'Sim.Solution.AutograderDescription': string;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.Fullscreen.Visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Saved': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
      readonly: true,
    },
  },
  'Sim.Submitted': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
      readonly: true,
    },
  },

  'Sim.Configuration.Assignment.Title': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Configuration.Assignment.Summary': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Configuration.Assignment.Resubmit': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Configuration.Assignment.Data': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },

  'Sim.Solution.Description': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Solution.ChartSettings': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Solution.DataSetIndex': {
    initialValue: -1,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Solution.AutograderDescription': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
