import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ICapiPanelAction,
  ModalRoot,
  CapiPanel,
  NotificationArea,
} from 'asu-sim-toolkit';

import { useStores } from '../../providers/store/use-stores';
import { getCapi } from '../../capi';
import { InstructorView } from '../InstructorView/InstructorView';
import { StudentView } from '../StudentView/StudentView';
import { NotificationItem } from '../../components/NotificationItem/NotificationItem';
import { versionHistory } from '../../version-history';
import { isDebugMode } from '../../config';

export const MainView = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    modalStore,
    notificationStore,
    recapStore: { showRecap, hasNewUpdates },
    fullscreenStore: { registerFullscreenElement },
    simContextStore: { forceSimMode, mode },
  } = useStores();

  useEffect(() => {
    if (!wrapperRef.current) return;

    showRecap();
    registerFullscreenElement(wrapperRef.current);
  }, [registerFullscreenElement, showRecap]);

  const debugActions: ICapiPanelAction[] = useMemo(
    () => {
      const actions: ICapiPanelAction[] = [];

      if (versionHistory.length > 0) {
        actions.push({
          iconId: hasNewUpdates ? 'newUpdates' : 'info',
          tooltip: 'Version history',
          onClick: () => showRecap(false),
        });
      }

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNewUpdates]
  );

  return (
    <div ref={wrapperRef} className="bg-white">
      <NotificationArea
        notificationStore={notificationStore}
        placement="bottom-center"
        itemComponent={NotificationItem}
      />
      <ModalRoot modalStore={modalStore}>
        <div className="h-screen w-screen overflow-hidden flex">
          {(isDebugMode || mode === 'AUTHOR') && (
            <CapiPanel
              additionalActions={debugActions}
              capi={getCapi()}
              onSimModeChange={forceSimMode}
            />
          )}
          <div className="relative h-full w-screen overflow-hidden flex items-center justify-center">
            {mode === 'AUTHOR' ? <InstructorView /> : <StudentView />}
          </div>
        </div>
      </ModalRoot>
    </div>
  );
});
