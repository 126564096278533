import React, { FC, PropsWithChildren } from 'react';
import { ChartData } from '../../stores/domain';
import { RemoteDataStoreStatus } from '../../stores/types';
import { ChartPlaceholder } from '../Chart/ChartPlaceholder';
import { Spinner } from '../Spinner/Spinner';

interface IRemoteDataRendererProps extends PropsWithChildren {
  status: RemoteDataStoreStatus;
  data: ChartData | null;
}

export const RemoteDataRenderer: FC<IRemoteDataRendererProps> = ({
  status,
  data,
  children,
}) => {
  if (status === RemoteDataStoreStatus.FETCHING) {
    return <Spinner className="min-w-[100px] min-h-[100px]" />;
  }

  if (status === RemoteDataStoreStatus.ERROR) {
    return (
      <ChartPlaceholder text="The data from chosen data set index couldn't be fetched." />
    );
  }

  if (!data) {
    return (
      <ChartPlaceholder text="There was no data found for chosen data set index." />
    );
  }

  return <>{children}</>;
};
