import { ToolkitIconId } from 'asu-sim-toolkit';

export const iconDefinitions = {
  unlink: {
    viewBox: '0 0 26 28',
    content: `
      <path fill="currentColor" d="M6.859 19.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719zM9.5 20.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM6 17c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM25.75 19c0 1.203-0.469 2.328-1.328 3.172l-2.297 2.281c-0.844 0.844-1.969 1.297-3.172 1.297s-2.344-0.469-3.187-1.328l-5.219-5.234c-0.266-0.266-0.469-0.562-0.656-0.875l3.734-0.281 4.266 4.281c0.562 0.562 1.563 0.578 2.125 0.016l2.297-2.281c0.281-0.281 0.438-0.656 0.438-1.047 0-0.406-0.156-0.781-0.438-1.062l-4.281-4.297 0.281-3.734c0.313 0.187 0.609 0.391 0.875 0.656l5.25 5.25c0.844 0.859 1.313 1.984 1.313 3.187zM16.109 7.688l-3.734 0.281-4.266-4.281c-0.281-0.281-0.656-0.438-1.062-0.438s-0.781 0.156-1.062 0.422l-2.297 2.281c-0.281 0.281-0.438 0.656-0.438 1.047 0 0.406 0.156 0.781 0.438 1.062l4.281 4.281-0.281 3.75c-0.313-0.187-0.609-0.391-0.875-0.656l-5.25-5.25c-0.844-0.859-1.313-1.984-1.313-3.187s0.469-2.328 1.328-3.172l2.297-2.281c0.844-0.844 1.969-1.297 3.172-1.297s2.344 0.469 3.187 1.328l5.219 5.234c0.266 0.266 0.469 0.562 0.656 0.875zM26 9c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM17.5 0.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM23.859 2.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719z" />
    `,
  },
  previous: {
    viewBox: '0 0 21 28',
    content: `
      <path fill="currentColor" d="M18.297 4.703l-8.297 8.297 8.297 8.297c0.391 0.391 0.391 1.016 0 1.406l-2.594 2.594c-0.391 0.391-1.016 0.391-1.406 0l-11.594-11.594c-0.391-0.391-0.391-1.016 0-1.406l11.594-11.594c0.391-0.391 1.016-0.391 1.406 0l2.594 2.594c0.391 0.391 0.391 1.016 0 1.406z" />
    `,
  },
  next: {
    viewBox: '0 0 19 28',
    content: `
      <path fill="currentColor" d="M17.297 13.703l-11.594 11.594c-0.391 0.391-1.016 0.391-1.406 0l-2.594-2.594c-0.391-0.391-0.391-1.016 0-1.406l8.297-8.297-8.297-8.297c-0.391-0.391-0.391-1.016 0-1.406l2.594-2.594c0.391-0.391 1.016-0.391 1.406 0l11.594 11.594c0.391 0.391 0.391 1.016 0 1.406z" />
    `,
  },
};

export type IconId = keyof typeof iconDefinitions | ToolkitIconId;
