import React, { FC, PropsWithChildren } from 'react';
import { ITabProps } from 'asu-sim-toolkit';
import classNames from 'classnames';

export interface IButtonProps extends PropsWithChildren, ITabProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;

  onClick(): void;
}

export const buttonPrimaryClasses = 'text-white bg-orange border border-orange';
export const buttonSecondaryClasses =
  'text-orange bg-white border border-white';
export const buttonBlueClasses = 'text-white bg-blue border border-blue';

export const Button: FC<IButtonProps> = ({
  children,
  onClick,
  autoFocus = false,
  className,
  inputRef,
  onKeyDown,
  disabled = false,
}) => (
  <button
    autoFocus={autoFocus}
    disabled={disabled}
    className={classNames(
      'focus:outline-none focus:ring-2 focus:ring-keyboardCue flex items-center justify-center px-[10px] py-[6px] min-h-[38px] truncate rounded-md text-md font-semibold',
      disabled && 'opacity-70 cursor-not-allowed',
      className ? className : buttonPrimaryClasses
    )}
    type="button"
    onClick={onClick}
    ref={inputRef}
    onKeyDown={onKeyDown}
  >
    {children}
  </button>
);
