import { IRemoteDataStore, RemoteDataStoreStatus } from './types';
import { action, makeObservable, observable, runInAction } from 'mobx';

type FetchingStrategy<T, O> = (options: O) => Promise<T>;

export class RemoteDataStore<T, O> implements IRemoteDataStore<T, O> {
  status: RemoteDataStoreStatus = RemoteDataStoreStatus.IDLE;
  data: T | null = null;
  private readonly fetchingStrategy: FetchingStrategy<T, O>;

  constructor(fetchingStrategy: FetchingStrategy<T, O>) {
    this.fetchingStrategy = fetchingStrategy;

    makeObservable(this, {
      status: observable,
      data: observable.shallow,

      fetch: action.bound,
    });
  }

  async fetch(options: O): Promise<void> {
    this.status = RemoteDataStoreStatus.FETCHING;
    try {
      const data = await this.fetchingStrategy(options);
      runInAction(() => {
        this.data = data;
        this.status = RemoteDataStoreStatus.READY;
      });
    } catch (e) {
      console.log(
        'Could not fetch data with these options:',
        options,
        'Reason:',
        e
      );
      runInAction(() => (this.status = RemoteDataStoreStatus.ERROR));
    }
  }
}
