import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Chart from 'react-google-charts';

import { useStores } from '../../providers/store/use-stores';
import { ChartData, ISolution } from '../../stores/domain';
import { Textarea } from '../Textarea/Textarea';
import { useResize } from '../../hooks/use-resize';
import { ChartPlaceholder } from '../Chart/ChartPlaceholder';
import { RemoteDataRenderer } from '../RemoteDataRenderer/RemoteDataRenderer';

export const DescriptionPage: FC = observer(() => {
  const {
    solutionStore: { solution, updateSolution, wordCount },
    chartDataStore: { data, status },
    assignmentConfigStore: {
      savedConfig: {
        data: { objective, minWords },
      },
    },
  } = useStores();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  useResize(chartContainerRef, (width) => {
    setChartWidth(width);
  });

  const update =
    <K extends keyof ISolution>(key: K) =>
    (newValue: ISolution[K]) => {
      updateSolution({ [key]: newValue });
    };

  return (
    <div className="flex flex-col lg:flex-row gap-sm lg:gap-lg">
      <div className="flex-1 lg:w-0">
        <p>Take a look at your graph, displayed below.</p>
        <p className="mt-6 mb-2">
          <span className="font-bold">Note:</span> Your chart may look different
          depending on the size of your screen.
        </p>
        <div
          className="flex-1 overflow-hidden w-full flex justify-center"
          ref={chartContainerRef}
        >
          <RemoteDataRenderer status={status} data={data}>
            {solution.chartSettings.type ? (
              <Chart
                chartType={solution.chartSettings.type}
                data={data as ChartData}
                options={{
                  ...solution.chartSettings.options,
                  width: chartWidth,
                }}
                height="450px"
                width="100%"
              />
            ) : (
              <ChartPlaceholder text="Please select a graph to see a preview." />
            )}
          </RemoteDataRenderer>
        </div>
      </div>
      <div className="flex-1 mb-4">
        <p>{objective}</p>
        <p className="my-6">
          Consider the objective above. Describe the chart design choices you
          made and how they supported your goal.
        </p>
        <Textarea
          value={solution.description}
          onChange={update('description')}
        />
        <div className="flex justify-between p-1">
          <div className="text-sm text-gray-400">
            Words:{' '}
            <span
              className={
                wordCount < minWords ? 'text-error-intense' : 'text-success'
              }
            >
              {wordCount}
            </span>
          </div>
          <div className="text-sm text-gray-400">
            Required words: {minWords}
          </div>
        </div>
      </div>
    </div>
  );
});
