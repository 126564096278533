import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Chart from 'react-google-charts';

import { useResize } from '../../hooks/use-resize';
import { useStores } from '../../providers/store/use-stores';
import { RemoteDataRenderer } from '../RemoteDataRenderer/RemoteDataRenderer';
import { ChartData } from '../../stores/domain';

export const TablePage: FC = observer(() => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const {
    assignmentConfigStore: {
      savedConfig: {
        data: { objective },
      },
    },
    chartDataStore: { status, data },
  } = useStores();

  useResize(chartContainerRef, (width) => {
    setChartWidth(width);
  });

  return (
    <div className="h-full flex flex-col gap-6">
      <p>
        The table below displays the data that you will use for this assignment.
        Your objective is to select a graph type and manipulate the chart
        considering the objective below. Take a moment to look over the data and
        any additional information, then when you are ready to proceed, click
        next.
      </p>
      <div className="h-0 flex-1 flex flex-col-reverse sm:flex-row gap-6">
        <div
          className="min-h-[250px] max-h-[600px] flex-1 overflow-hidden"
          ref={chartContainerRef}
        >
          <h6 className="font-bold mb-2">Table</h6>
          <RemoteDataRenderer status={status} data={data}>
            <div className="h-full overflow-auto">
              <Chart
                chartType="Table"
                data={data as ChartData}
                options={{ showRowNumber: true, width: chartWidth }}
                width="100%"
              />
            </div>
          </RemoteDataRenderer>
        </div>
        <div className="sm:flex-1">
          <h6 className="font-bold mb-2">Objective</h6>
          <p>{objective}</p>
        </div>
      </div>
    </div>
  );
});
