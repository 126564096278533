import { CapiBoundStore, ICAPI, Mapper } from 'asu-sim-toolkit';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { ICapiModel } from '../capi';
import { IRootStore, ISolutionStore } from './types';
import { IChartSettings, ISolution, View } from './domain';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import { serialize, unserialize } from './helpers';

const EMPTY_SETTINGS = { type: 'LineChart' };

const mapChartSettingsToCapi: Mapper<IChartSettings, string> = (input) =>
  serialize(input);

const mapChartSettingsFromCapi: Mapper<string, IChartSettings> = (
  input: string
) => {
  if (!input) return EMPTY_SETTINGS;

  try {
    return unserialize(input);
  } catch (e) {
    console.warn('Could not parse chart settings');
    return EMPTY_SETTINGS;
  }
};

class Solution extends CapiBoundStore<ICapiModel> implements ISolution {
  chartSettings: IChartSettings = {};
  description = '';

  constructor(capi: ICAPI<ICapiModel>, synchronize?: 'twoWay' | 'fromCAPI') {
    super(capi);

    makeObservable(this, {
      chartSettings: observable,
      description: observable,
      update: action.bound,
    });

    if (synchronize === 'twoWay') {
      this.bindToCapi('description', 'Sim.Solution.Description');
      this.bindToCapi(
        'chartSettings',
        'Sim.Solution.ChartSettings',
        mapChartSettingsFromCapi,
        mapChartSettingsToCapi
      );
    }

    if (synchronize === 'fromCAPI') {
      this.synchronizeFromCapi('description', 'Sim.Solution.Description');
      this.synchronizeFromCapi(
        'chartSettings',
        'Sim.Solution.ChartSettings',
        mapChartSettingsFromCapi
      );
    }
  }

  update(newData: Partial<ISolution>) {
    if (newData.description !== undefined) {
      this.description = newData.description;
    }

    if (newData.chartSettings !== undefined) {
      this.chartSettings = newData.chartSettings;
    }
  }
}

export class SolutionStore
  extends CapiBoundStore<ICapiModel>
  implements ISolutionStore
{
  private rootStore: IRootStore;
  savedSolution: Solution;
  solution: Solution;
  isSaved = true;
  isSubmitted = false;
  dataSetIndex = -1;
  view: View;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;
    this.savedSolution = new Solution(capi, 'twoWay');
    this.solution = new Solution(capi, 'fromCAPI');
    this.view = View.Edit;

    makeObservable(this, {
      isSaved: observable,
      isSubmitted: observable,
      dataSetIndex: observable,
      view: observable,

      updateSolution: action.bound,
      save: action.bound,
      submit: action.bound,
      changeView: action.bound,
      updateDataSetIndex: action,
      canSubmit: computed,
      autograderDescription: computed,
    });

    this.bindToCapi('isSaved', 'Sim.Saved');
    this.bindToCapi('isSubmitted', 'Sim.Submitted');
    this.bindToCapi('dataSetIndex', 'Sim.Solution.DataSetIndex');
    this.synchronizeToCapi(
      'autograderDescription',
      'Sim.Solution.AutograderDescription'
    );

    reaction(
      () => [
        this.dataSetIndex,
        this.rootStore.assignmentConfigStore.savedConfig.data.dataSourceUrls
          .length,
      ],
      () => {
        this.updateDataSetIndex();

        console.log('Solution store reaction:', {
          dataSetIndex: this.dataSetIndex,
          URLs: this.rootStore.assignmentConfigStore.savedConfig.data
            .dataSourceUrls.length,
          DSI: this.dataSetIndex,
        });

        if (this.dataSetIndex === -1) {
          return;
        }

        const sheetId =
          this.rootStore.assignmentConfigStore.savedConfig.data.dataSourceUrls[
            this.dataSetIndex
          ];
        this.rootStore.chartDataStore.fetch({ sheetUrl: sheetId });
      },
      { fireImmediately: true }
    );
  }

  updateDataSetIndex() {
    const dataSetCount =
      this.rootStore.assignmentConfigStore.savedConfig.data.dataSourceUrls
        .length;

    if (this.dataSetIndex !== -1 || dataSetCount === 0) {
      return;
    }

    this.dataSetIndex = Math.round(Math.random() * (dataSetCount - 1));
  }

  updateSolution(newData: Partial<ISolution>) {
    this.solution.update(newData);
    this.isSaved = false;
  }

  save() {
    const { chartSettings, description } = this.solution;

    this.savedSolution.update({ chartSettings, description });
    this.isSaved = true;

    if (this.isSubmitted) return;
    this.rootStore.notificationStore.addNotification(
      'Your homework is saved. You can continue editing or close this window and come back later.'
    );
  }

  async submit() {
    const {
      notificationStore,
      assignmentConfigStore: {
        savedConfig: { allowResubmit },
      },
    } = this.rootStore;
    const isConfirmed = await this.rootStore.modalStore.modal(
      ConfirmationModal,
      {
        title: 'Are you sure?',
        message: allowResubmit
          ? 'Once submitted, you will be able to make additional edits to your assignment.'
          : 'Once submitted, you cannot go back to make additional edits to your assignment.',
        confirmLabel: 'Submit',
        denyLabel: 'Cancel',
      }
    );

    if (!isConfirmed) return;

    this.isSubmitted = true;
    this.save();
    this.changeView(View.Overview);
    notificationStore.addNotification(
      allowResubmit
        ? 'Your work is submitted. If you want, you can still edit your assignment.'
        : 'Your work is submitted. You cannot edit your assignment anymore.'
    );

    this.capi.triggerCheck();
  }

  changeView(view: View) {
    this.view = view;
  }

  get wordCount() {
    return this.solution.description.split(' ').filter(Boolean).length;
  }

  get canSubmit() {
    return Boolean(
      this.solution.chartSettings.type &&
        this.wordCount >=
          this.rootStore.assignmentConfigStore.savedConfig.data.minWords
    );
  }

  get autograderDescription() {
    if (!this.solution.chartSettings.type) return '';

    return `Chosen chart type: ${this.solution.chartSettings.type}.
    
Description:
${this.solution.description}`;
  }
}
