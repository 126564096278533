import React, { FC, PropsWithChildren } from 'react';

import { StoreContext } from './store-context';
import { IRootStore } from '../../stores/types';

export interface IStoreProviderProps extends PropsWithChildren {
  store: IRootStore;
}

export const StoreProvider: FC<IStoreProviderProps> = ({ store, children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
