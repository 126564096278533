import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Chart from 'react-google-charts';

import { Header } from '../../components/Header/Header';
import { useStores } from '../../providers/store/use-stores';
import { useResize } from '../../hooks/use-resize';
import { isSafari } from 'asu-sim-toolkit';
import { IconId } from '../../icon-definitions';
import { RemoteDataRenderer } from '../../components/RemoteDataRenderer/RemoteDataRenderer';
import { ChartData, View } from '../../stores/domain';
import { Button } from '../../components/Button/Button';

export const SolutionView: FC = observer(() => {
  const {
    assignmentConfigStore: {
      savedConfig: { title, allowResubmit },
    },
    chartDataStore: { status, data },
    fullscreenStore: { toggleFullscreen, fullscreenControlState, isFullscreen },
    solutionStore: { solution, changeView },
  } = useStores();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  useResize(chartContainerRef, (width) => {
    setChartWidth(width);
  });

  const headerAction =
    fullscreenControlState.isVisible && !isSafari
      ? {
          iconId: (isFullscreen ? 'fullscreen_exit' : 'fullscreen') as IconId,
          disabled: !fullscreenControlState.isEnabled,
          onClick: toggleFullscreen,
        }
      : undefined;

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={`${title} - Submitted solution`} action={headerAction}>
        {allowResubmit && (
          <Button onClick={() => changeView(View.Edit)}>Edit assignment</Button>
        )}
      </Header>
      <div className="flex flex-col p-6 w-full max-w-[1100px] mx-auto overflow-y-auto">
        <div
          className="flex-1 overflow-hidden flex justify-center min-h-[450px]"
          ref={chartContainerRef}
        >
          <RemoteDataRenderer status={status} data={data}>
            <Chart
              chartType={solution.chartSettings.type}
              data={data as ChartData}
              options={{ ...solution.chartSettings.options, width: chartWidth }}
              height="450px"
              width="100%"
            />
          </RemoteDataRenderer>
        </div>
        <p>{solution.description}</p>
      </div>
    </div>
  );
});
