import {
  GoogleChartOptions,
  GoogleChartWrapperChartType,
} from 'react-google-charts';

export interface IBaseAssignmentConfig {
  title: string;
  summary?: string;
  allowResubmit: boolean;
}

export interface IAssignmentConfig extends IBaseAssignmentConfig {
  data: IToolSpecificAssignmentConfig;
}

export type FlatAssignmentConfig = IBaseAssignmentConfig &
  IToolSpecificAssignmentConfig;

// -- tool-specific type definitions --
export interface IToolSpecificAssignmentConfig {
  dataSourceUrls: string[];
  minWords: number;
  maxScore: number;
  objective: string;
}

export interface IChartSettings {
  type?: GoogleChartWrapperChartType;
  options?: Omit<GoogleChartOptions, 'backgroundColor'>;
}

export interface ISolution {
  chartSettings: IChartSettings;
  description: string;
}

export type ChartData = any[] | object; // NOTE: That's Google Chart's definition, not mine :|

export enum View {
  Edit = 'edit',
  Overview = 'overview',
}
