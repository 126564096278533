import {
  RecapStore,
  ModalStore,
  NotificationStore,
  INotificationStore,
  IModalStore,
  IRecapStore,
  IFullscreenStore,
  FullscreenStore,
  SimContextStore,
  ISimContextStore,
  CAPI,
} from 'asu-sim-toolkit';

import { isDebugMode } from '../config';
import { ICapiModel } from '../capi';
import {
  IAppStore,
  IAssignmentConfigStore,
  IRootStore,
  ISolutionStore,
} from './types';
import { AppStore } from './app-store';
import { versionHistory } from '../version-history';
import { AssignmentConfigStore } from './assignment-config-store';
import { SolutionStore } from './solution-store';
import { chartDataSource } from '../sources/chart-data-source-google-sheets';
import { RemoteDataStore } from './remote-data-store';
import { ChartData } from './domain';

export class RootStore implements IRootStore {
  appStore: IAppStore;
  assignmentConfigStore: IAssignmentConfigStore;
  solutionStore: ISolutionStore;
  chartDataStore: RemoteDataStore<ChartData, { sheetUrl: string }>;
  notificationStore: INotificationStore;
  modalStore: IModalStore;
  recapStore: IRecapStore;
  fullscreenStore: IFullscreenStore;
  simContextStore: ISimContextStore;

  constructor(capi: CAPI<ICapiModel>) {
    if (isDebugMode) {
      (window as any).capi = capi;
      (window as any).store = this;
    }

    this.notificationStore = new NotificationStore();
    this.modalStore = new ModalStore();
    this.recapStore = new RecapStore(this.modalStore, versionHistory);
    this.fullscreenStore = new FullscreenStore(capi);
    this.simContextStore = new SimContextStore(capi);

    this.appStore = new AppStore(this, capi);
    this.chartDataStore = new RemoteDataStore<ChartData, { sheetUrl: string }>(
      chartDataSource.fetchChartData
    );
    this.assignmentConfigStore = new AssignmentConfigStore(this, capi);
    this.solutionStore = new SolutionStore(this, capi);
  }
}
