import React from 'react';
import { observer } from 'mobx-react-lite';
import { Header } from '../../components/Header/Header';
import { TextInput } from '../../components/TextInput/TextInput';
import { Textarea } from '../../components/Textarea/Textarea';
import { useStores } from '../../providers/store/use-stores';
import { DataSourcesInput } from '../../components/DataSourcesInput/DataSourcesInput';
import { FlatAssignmentConfig } from '../../stores/domain';
import { Button, buttonSecondaryClasses } from '../../components/Button/Button';
import { InstructorViewSection } from './InstructorViewSection';
import { NumberInput } from '../../components/NumberInput/NumberInput';
import { Checkbox } from '../../components/Checkbox/Checkbox';

export const InstructorView = observer(() => {
  const {
    assignmentConfigStore: {
      currentConfig: { data, title, summary, allowResubmit },
      discard,
      save,
      updateData,
      dataSources,
      updateDataSource,
      canSave,
      isChanged,
    },
  } = useStores();

  const updateField =
    <T extends keyof FlatAssignmentConfig>(key: T) =>
    (newValue: FlatAssignmentConfig[T]) =>
      updateData({ [key]: newValue });

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={title || 'Configure assignment'}>
        <div className="flex gap-3">
          <Button
            className={buttonSecondaryClasses}
            onClick={discard}
            disabled={!isChanged}
          >
            Discard
          </Button>
          <Button onClick={save} disabled={!canSave}>
            Save
          </Button>
        </div>
      </Header>
      <div className="overflow-y-auto">
        <InstructorViewSection title="Basic Assignment Details">
          <TextInput
            label="Title"
            onChange={updateField('title')}
            value={title}
          />
          <Textarea
            label="Summary"
            onChange={updateField('summary')}
            value={summary || ''}
            required={false}
          />
          <Checkbox
            label="Allow students to resubmit their task"
            value={allowResubmit}
            onChange={(v) => updateData({ allowResubmit: v })}
          />
        </InstructorViewSection>
        <InstructorViewSection title="Assignment configuration">
          <div className="flex flex-col sm:flex-row gap-md">
            <NumberInput
              description="Required minimum word count"
              value={data.minWords}
              onChange={(v) => updateData({ minWords: Number(v) })}
            />
            <NumberInput
              description="Maximum score for the assignment"
              value={data.maxScore}
              onChange={(v) => updateData({ maxScore: Number(v) })}
            />
          </div>
        </InstructorViewSection>
        <InstructorViewSection title="Assignment objective">
          <Textarea
            description="This text will be displayed to the students"
            label="Objective"
            onChange={updateField('objective')}
            value={data.objective || ''}
          />
          <DataSourcesInput
            label="Data source URLs"
            inputDescription="Enter url to your Google Spreadsheet. Please make sure that anyone on the internet can view the spreadsheet!"
            value={data.dataSourceUrls}
            dataSources={dataSources}
            updateDataSource={updateDataSource}
            onChange={updateField('dataSourceUrls')}
          />
        </InstructorViewSection>
      </div>
    </div>
  );
});
