import React, { FC, useRef, useState } from 'react';
import { IGenericModal } from 'asu-sim-toolkit';
import { Button, buttonPrimaryClasses } from '../Button/Button';
import classNames from 'classnames';
import Chart from 'react-google-charts';
import { useResize } from '../../hooks/use-resize';
import { observer } from 'mobx-react-lite';

interface IDataTableModalProps extends IGenericModal<boolean> {
  title?: string;
  confirmLabel?: string;
  data: any;
}

export const DataTableModal: FC<IDataTableModalProps> = observer(
  ({ title, setOverlayClickHandler, onResolve, confirmLabel = 'Ok', data }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState(0);
    useResize(chartContainerRef, (width) => {
      setChartWidth(width);
    });

    setOverlayClickHandler(() => onResolve(false));

    return (
      <div className="bg-white rounded-md shadow-outer flex flex-col overflow-hidden w-[500px] max-w-[100vw] max-h-[90vh]">
        {title && (
          <div className="font-semibold bg-blue text-white p-md">{title}</div>
        )}
        <div
          className="flex-1 overflow-hidden overflow-y-auto"
          ref={chartContainerRef}
        >
          <Chart
            chartType="Table"
            data={data}
            options={{
              showRowNumber: true,
              width: chartWidth,
              minHeight: '',
            }}
            width="100%"
          />
        </div>
        <div className="flex gap-md p-md border-t border-gray-200 justify-end">
          <Button
            className={classNames(buttonPrimaryClasses, 'min-w-[100px]')}
            onClick={() => onResolve(true)}
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    );
  }
);
