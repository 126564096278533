import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { versionCallout } from './utils/version-callout';
import { ICapiModel, capiSchema, getCapi } from './capi';
import { CAPI } from 'asu-sim-toolkit';
import { LoadingScreen } from './views/LoadingScreen/LoadingScreen';

versionCallout('Data Tool');

const AppLoader = () => {
  const [capi, setCapi] = useState<CAPI<ICapiModel> | null>(null);

  useEffect(() => {
    const initCapi = () =>
      new Promise<CAPI<ICapiModel>>((resolve) => {
        const capi = getCapi();
        capi.init(capiSchema, () => {
          resolve(capi);
        });
      });

    const initApp = async () => {
      const capi = await initCapi();
      setCapi(capi);
    };

    initApp();
  }, []);

  return capi ? <App capi={capi} /> : <LoadingScreen />;
};

createRoot(document.getElementById('root') || document.body).render(
  <AppLoader />
);
